import React, { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import { Device } from "@capacitor/device";
import "./App.css";
import gsap from "gsap";
import WhatsAppIcon from "./components/WhatsAppIcon/WhatsAppIcon";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import PrivacyAndPolicy from "./components/PrivacyAndPolicy/PrivacyAndPolicy";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ThankyouPage from "./components/ThankyouCard/ThankyouCard";
import ThankYouPageExisting from "./components/ThankYouPageExisting/ThankYouPageExisting";
import BMLeadCreation from "./components/BmLeadCreation/BmLeadCreation";


gsap.registerPlugin(ScrollTrigger);
const App: React.FC = () => {
  const currentPath = window.location.pathname;
  // Check if the current path is "bm-lead-creation"
  const isBmLeadCreationPath = currentPath === "/bm-lead-creation";
  const getDeviceDetails = async () => {
    const storedDeviceId = sessionStorage.getItem("deviceId");
    const storedDeviceType = sessionStorage.getItem("devicePlatform");

    if (!storedDeviceId || !storedDeviceType) {
      const devicePlatform = (await Device.getInfo()).platform;
      const deviceId = await Device.getId();

      sessionStorage.setItem("deviceId", deviceId.identifier || "");
      sessionStorage.setItem("devicePlatform", devicePlatform || "");
    }
  };

  useEffect(() => {
    getDeviceDetails();
  }, []);

  return (
    <div>
      <Router>
        <CssBaseline></CssBaseline>
        <Routes>
          <Route path="/">
            {/* Nested routes, using Layout as the base */}
            <Route index element={<Home />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="thank-you" element={<ThankyouPage />} />
            <Route path="request-already-submitted" element={<ThankYouPageExisting />} />
            <Route path="bm-lead-creation" element={<BMLeadCreation />} />
          </Route>
        </Routes>
      </Router>
      {!isBmLeadCreationPath && <WhatsAppIcon />}</div>
  );
};

export default App;
