import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TACData } from "./TACData";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate();

  // Handle back button click
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Header />
      </Box>
      <Box
        sx={{ flexGrow: 1, width: { xs: "100%", md: "80%" }, margin: "auto", paddingTop: {md: "90px"} }}
      >
        {/* AppBar with custom styles */}
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: "#FFF",
            boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.04)",
            display: { xs: "block", md: "none" },
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleBackClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15 6L9 12L15 18"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Terms and Conditions
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            margin: "1% 0%",
            padding: "2% 0%",
          }}
        >
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "normal",
              marginLeft: "3%",
              display: "flex",
              alignItems: "center",
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>

        {/* Main content */}
        <Box sx={{ padding: { xs: 3, md: "2% 0%" } }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            This document is an electronic record in terms of the Information
            Technology Act, 2000 and Rules there under pertaining to electronic
            records as applicable and amended from time to time. This electronic
            record is generated by a computer system and does not require any
            physical or digital signatures.
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              margin: "2% 0%",
            }}
          >
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries Guidelines and
            Digital Media Ethics Code) Rules, 2021 that require publishing the
            rules and regulations, privacy policy and the terms and conditions
            for access or usage of <a style={{color:"blue"}} href="/">https://makemywedding.com</a>(the
            "Website").
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "normal",
              margin: "2% 0%",
            }}
          >
            PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY BEFORE
            USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING THE
            WEBSITE, IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND
            CONDITIONS ("Agreement").
          </Typography>
          {TACData.map((item, index) => (
            <React.Fragment key={index}>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "14px" },
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  marginTop: "3%", // adjust margin based on index
                }}
              >
                {index + 1}. {item.heading}:
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "14px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  margin: "2% 0%",
                }}
                dangerouslySetInnerHTML={{ __html: item.paragraph }} // Use dangerouslySetInnerHTML here
              >
              </Typography>
              {item.paragraph2 ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph2}
                </Typography>
              ) : item.paragraph2email ? (
                <Box
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                    textDecoration: "none", // Optional: to remove underline
                  }}
                >
                  <span>Email: </span>
                  <a href={`mailto:${item.paragraph2email}`}>
                    {item.paragraph2email}
                  </a>
                </Box>
              ) : null}
              {item.paragraph3 ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph3}
                </Typography>
              ) : null}
              {item.paragraph4 ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph4}
                </Typography>
              ) : null}
              {item.paragraph5 ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph5}
                </Typography>
              ) : null}
              {item.paragraph6 ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph6}
                </Typography>
              ) : null}
              {item.paragraph7 !== null ? (
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    margin: "2% 0%",
                  }}
                >
                  {item.paragraph7}
                </Typography>
              ) : item.paragraph7List ? (
                item.paragraph7List.map((data: string, index: number) => (
                  <Typography
                    key={index} // Add a key prop for each item in the list
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      margin: "2% 0%",
                    }}
                  >
                    {String.fromCharCode(65 + index)}. {data}{" "}
                    {/* Convert index to capital letter */}
                  </Typography>
                ))
              ) : null}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Footer />
      </Box>
    </>
  );
};

export default TermsAndConditions;
