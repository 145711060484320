import React, { useEffect, useRef, useState } from "react";
// import Grid from "@mui/material/Grid2";
import Grid from '@mui/material/Grid';
import {
  Button,
  Typography,
  Box,
  TextField,
  Divider,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import OTPModal from "../OTPModal/OTPModal";
import "./style.css";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";
import ThankyouCardForExisting from "../ThankyouCardForExisting/ThankyouCardForExisting";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import handheart from "../../assets/images/hand-heart.png"
import manager from "../../assets/images/relationmanager.png"
import discount from "../../assets/images/discount.svg"
import star from "../../assets/images/star.png"
import headerImage from "../../assets/images/new-header-image.png"
import mobileBanner from "../../assets/images/newMobileBanner.png"
import locationLogo from "../../assets/images/location.svg"
import { useMutation } from "@apollo/client";
import { UPDATE_MMW_CUSTOMER_QUERY } from "../../apollo/updateEnquiry";


const NewBannerSection: React.FC = () => {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [editForm, setEditForm] = useState({ name: "", mobileNumber: "" });
  const navigate = useNavigate();
  const [isThankyouCardForExisting, setIsThankyouCardForExisting] =
    useState(false);
  const { createCustomerEventLog } = useCustomerEventLogs();
  const [updateMMWCustomerQuery] = useMutation(UPDATE_MMW_CUSTOMER_QUERY);
  const currentDate = new Date().toISOString();


  const isMobile = useMobile();

  const formikRef: any = useRef(null); // Reference to Formik instance

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({ name: userName, mobile: userMobileNumber });
    }
  }, [editForm]);

  const location = useLocation();
  const [utmParams, setUtmParams] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    match_type: '',
    keyword: '',
    campaign_id: '',
    device: '',
    device_model: '',
    placement: '',
    adgroup: ''
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const match_type = searchParams.get('Matchtype'); // Case-sensitive match
    const keyword = searchParams.get('Keyword');
    const campaign_id = searchParams.get('CampaignID');
    const device = searchParams.get('device');
    const device_model = searchParams.get('DeviceModel');
    const placement = searchParams.get('Placement');
    const adgroup = searchParams.get('adgroup');
    // Set all UTM parameters
    setUtmParams({
      utm_source: source || '',
      utm_medium: medium || '',
      utm_campaign: campaign || '',
      match_type: match_type || '',
      keyword: keyword || '',
      campaign_id: campaign_id || '',
      device: device || '',
      device_model: device_model || '',
      placement: placement || '',
      adgroup: adgroup || ''
    });
  }, [location.search]);


  const renderThankYou = () => {
    if (isMobile) {
      return (
        <ThankyouCardForExisting
          open={true}
          onClose={() => setIsThankyouCardForExisting(false)}
        />
      );
    } else {
      navigate("/request-already-submitted");
      return null; // Return null to avoid rendering anything
    }
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit mobile number')
      .test('not-all-zeros', 'Mobile number cannot be all zeros', (value) => value !== '0000000000')
      .test('starts-with-valid-digit', 'Mobile number must start with a digit 6 or higher', (value: any) => {
        return value && parseInt(value[0], 10) >= 6;
      })
      .required('Mobile number is required'),
  });

  const handleSubmit = async (values: any, resetForm: () => void) => {
    const existingData = await getEnquiryDataUsingMobileNumber(
      values.mobile.toString()
    );
    const isQueryExist = existingData?.mmw_customer_query?.[0]?.id;
    // console.log(existingData?.mmw_customer_contact?.[0],"existingData?.mmw_customer_contact?.[0]?")
    // const isPhoneVerified = existingData?.mmw_customer_contact?.[0]?.is_phone_verified
    if (isQueryExist) {
      resetForm();
      setIsThankyouCardForExisting(true);
      updateMMWCustomerQuery({
        variables: {
          data: {
            updated_at: {
              set: currentDate
            },
          },
          where: {
            id: isQueryExist
          }
        }
      })
    } else {
      resetForm();
      setUserName(values.name);
      setUserMobileNumber(values.mobile.toString());
      setIsOtpModalOpen(true);
      createCustomerEventLog({
        event_name: `otp_screen_open_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event"
      })
    }
  };
  return (
    <>
      <Box className="banner-container">
        <Box className="main-container">
          <Box className="left-content">
          <Typography sx={{
              fontSize: {
                md: "34px",
                xs: "20px"
              }, fontWeight: "600",
              fontFamily: "Poppins",
              position: {
                xs: "relative",
              },
              bottom: {
                xs: "17%",
                md: "0"
              },
              padding: {
                xs: "2% 30px 0 0px",
                md: "unset"
              },
              background: {
                xs: "linear-gradient(180deg, rgba(255, 246, 246, 0) 0%, #FFF6F6 68.21%)",
                md: "none",
              },
              zIndex: "1"
            }}>
              We will help you select the {isMobile && <br />}right{!isMobile && <br />}
              <Typography sx={{
                fontSize: {
                  md: "34px",
                  xs: "19px",
                }, fontWeight: "600", color: "#EE1C27", fontFamily: "Poppins",
              }} component="span"> service providers at the {isMobile && <br />}best price.</Typography>
            </Typography>
            <Box
              sx={{
                position: "relative",
                bottom: {
                  xs: "57px",
                  md: "15px"
                },
                left: 0,
                width: {
                  xs: '88%',
                  md: '45%'
                },
                height: '1px', // Covers only the bottom 40% of the parent div
                background: 'linear-gradient(90deg, #393B3C 0%, rgba(255, 255, 255, 0) 120.7%)',
                pointerEvents: 'none'
              }}
            />
            <Typography sx={{
                position: "relative",
                bottom: {
                  md: "25px",
                  xs: "45px"
                },
                fontSize: {
                  md: "20px",
                  xs: "12px",
                }, fontFamily: "Poppins", fontStyle: "italic",
              }} component="span"><Typography sx={{
                fontSize: {
                  md: "20px",
                  xs: "12px",
                }, fontWeight: "600", fontFamily: "Poppins", fontStyle: "italic",
              }} component="span">Save time, effort & money
            </Typography> on your dream wedding!
            </Typography>
            {
              !isMobile &&
              <>
                <Box className="benefits-container" sx={{flexDirection: "column",width: {md: "40%"}, alignItems: {md: "unset"}, position: "relative", top: "-10px", gap: '0px',}}>
                  <Box className="item">
                    <Box className="item">
                      <img src={manager} alt="" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>
                        Dedicated{!isMobile && <br />} Relationship Managers
                      </Typography>
                    </Box>
                    <Divider className="divider" orientation="vertical" variant="middle" flexItem sx={{
                      position: "relative",
                      left: "20px"
                    }} />
                    <Box className="item" sx={{
                      position: "relative",
                      left: "25px"
                    }}>
                      <img src={star} alt="" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>
                        Trusted & reliable{!isMobile && <br />} vendors
                      </Typography>
                    </Box>
                  </Box>{!isMobile && <br />}
                  <Box className="item">
                    <Box className="item">
                      <img src={handheart} alt="" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>Best negotiated{!isMobile && <br />}
                        {" "} rates
                      </Typography>
                    </Box>
                    <Divider className="divider" orientation="vertical" variant="middle" flexItem sx={{
                      position: "relative",
                      left: "71px"
                    }}/>
                    <Box className="item" sx={{
                      position: "relative",
                      left: "77px"
                    }}>
                      <img src={discount} alt="" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>Exclusive discounts on {!isMobile && <br />}
                        {" "} wedding shopping
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            }
            {
              isMobile &&
              <>
                <Box className="benefits-container" sx={{position: "relative", left: "12px"}}>
                  <Box className="item">
                    <img src={manager} alt="" />
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>
                      Dedicated{!isMobile && <br />} Relationship Managers
                    </Typography>
                  </Box>
                  <Divider className="divider" orientation="vertical" variant="middle" flexItem />
                  <Box className="item">
                    <img src={star} alt="" />
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>
                      Trusted & reliable{!isMobile && <br />} vendors
                    </Typography>
                  </Box>
                  <Divider className="divider" orientation="vertical" variant="middle" flexItem />
                  <Box className="item">
                    <img src={handheart} alt="" />
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>Best negotiated{!isMobile && <br />}
                      {" "} rates
                    </Typography>
                  </Box>
                  <Divider className="divider" orientation="vertical" variant="middle" flexItem />
                  <Box className="item">
                    <img src={discount} alt="" />
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", }}>Exclusive discounts on wedding shopping
                    </Typography>
                  </Box>
                </Box>
              </>
            }
            <Box className="form-container" 
                sx={{position: "relative", width: "58%", zIndex: 1}}>
              <Formik
                innerRef={formikRef}
                initialValues={{ name: "", mobile: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm);
                  createCustomerEventLog({
                    event_name: `click_on_request_call_back_Banner_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <Form>
                    <Box />
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* Name Field */}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{ borderRight: "2px solid #FFF0F0" }}
                      >
                        <Field
                          name="name"
                          autoComplete="off"
                          as={TextField}
                          placeholder="Enter your name"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                          value={values.name}
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              ml: 2,
                              color: "#000000 !important",
                              fontFamily: "Poppins !important",
                              fontSize: "14px",
                              fontWeight: "400",
                              backgroundColor: "transparent",
                              "::placeholder": {
                                color: "#000 !important",
                                fontFamily: "Poppins !important",
                                fontWeight: "400",
                                fontSize: "14px",
                                opacity: 1,
                              },
                              "& input::placeholder": {
                                color: "#000 !important", // Ensures input placeholder color
                                fontFamily: "Poppins !important",
                                fontWeight: "400",
                                fontSize: "14px",
                                opacity: 1,
                              },
                              "&:focus-within": {
                                backgroundColor: "transparent",
                              },
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                        />
                        {touched.name && errors.name && (
                          <Typography
                            sx={{
                              ml: 2,
                              color: "#EF2965",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            {errors.name}
                          </Typography>
                        )}
                      </Grid>

                      {/* Mobile Number Field */}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{ pl: { xs: 0, sm: "2%" } }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              width: { md: "87%", lg: "88%", xl: "88%" },
                            }}
                          >
                            <Field
                              name="mobile"
                              as={TextField}
                              autoComplete="off"
                              placeholder="Enter mobile number"
                              fullWidth
                              variant="standard"
                              onChange={(e: any) => {
                                const { value } = e.target;
                                if (/^\d*$/.test(value) && value.length <= 10) {
                                  setFieldValue("mobile", value);
                                  if (value.length === 10) {
                                    createCustomerEventLog({
                                      event_name: `customer_entered_phone_number_${isMobile ? "mobile" : "desktop"}`,
                                      event_type: "click_event",
                                    });
                                  }
                                }
                              }}
                              type="number"
                              value={values.mobile}
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  color: "#000000 !important",
                                  fontFamily: "Poppins !important",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  backgroundColor: "transparent",
                                  "&::placeholder": {
                                    color: "#000 !important",
                                    fontFamily: "Poppins !important",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    opacity: 1,
                                  },
                                  "& input::placeholder": {
                                    color: "#000 !important", // Ensures input placeholder color
                                    fontFamily: "Poppins !important",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    opacity: 1,
                                  },
                                  "&:focus-within": {
                                    backgroundColor: "transparent",
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                  inputProps: {
                                    style: {
                                      MozAppearance: "textfield",
                                      appearance: "textfield",
                                    },
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Box>
                        {touched.mobile && errors.mobile && (
                          <Typography
                            sx={{
                              color: "#EF2965",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            {errors.mobile}
                          </Typography>
                        )}
                      </Grid>

                      {/* Submit Button */}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80px",
                        }}
                      >
                        <div style={{ width: "100%", textAlign: "center", marginRight: "6px" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              width: { xs: "100%", sm: "90%", md: "100%" },
                              height: "52px",
                              borderRadius: "12px",
                              background: "#BE142D",
                              textTransform: "none",
                              color: "#FFF",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Get FREE Consultation
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
            <div className="consent-cta" style={{ color: "#7B5E82", fontFamily: "Poppins", fontStyle: "normal", fontSize: "12px", fontWeight: "400", }}>*By clicking, I agree to the <Link style={{ color: "#7B5E82" }} to="/terms-and-conditions"><span>T&C</span></Link> and <Link style={{ color: "#7B5E82" }} to="/privacy-policy"><span>Privacy Policy</span></Link></div>
            <Box className="availability" sx={{width: {
                    md: "33%"
                  },marginBottom: {md: "20px"},}}>
              <Typography className="availability-text"
                sx={{
                  fontSize: {
                    xs: "12px",
                    md: "14px"
                  }
                  , fontWeight: "500", fontFamily: "Poppins !important",
                }}
              ><img src={locationLogo} alt="location" />We’re currently available only in Tamil Nadu</Typography>
            </Box>
          </Box>
          <Box className="right-content" sx={{position: {md: "absolute"}, right: {md: 0},}}>
            <img className="desktopBanner" src={headerImage} alt="hero-image" />
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                minHeight: '280px',
                backgroundImage: `url(${mobileBanner})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'none', // Default hidden
                '@media (max-width:768px)': {
                  display: 'block', // Visible on screens 768px and below
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '40%', // Covers only the bottom 40% of the parent div
                  background: 'linear-gradient(180deg, rgba(255, 246, 246, 0) 20%, #FFF6F6 68.21%)',
                  pointerEvents: 'none',
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className="price-section">
          <Typography
            sx={{
              fontSize: {
                xs: "12px",
                md: "16px"
              },
              textAlign: "center"
              , fontWeight: "500",
              fontFamily: "Poppins !important",
              padding: "1rem",
              lineHeight: {
                md: "24px",
                xs: "18px"
              }
            }}
          >
            Prices start from <span>₹999</span> for any single {isMobile && <br />}category, or <span>₹3999</span> for 4 or more categories
          </Typography>
        </Box>
        <Box className="info-section"
          style={{
            background: `linear-gradient(0deg, #FFFFFF, #FFFFFF),
          linear-gradient(270deg, #FFF6F6 0%, #FFFFFF 50%, #FFF6F6 100%) !important`,
          }}>
          <Typography className="info-text" sx={{
            fontSize: {
              xs: "14px",
              md: "20px"
            },
            lineHeight: "20px",
            textAlign: "center"
            , fontWeight: "500", fontFamily: "Poppins !important",
          }}>
            We partner with trusted service providers in every category, from <span>mandap</span> to {isMobile && <br />} <span>photography</span>, <span>makeup artists</span>, {!isMobile && <br />} <span>catering</span>, and <span>decor</span>, covering every detail to bring your dream wedding to life
          </Typography>
        </Box>
      </Box>
      {isOtpModalOpen ? (
        <OTPModal
          open={isOtpModalOpen}
          onClose={() => setIsOtpModalOpen(false)}
          name={userName}
          mobileNumber={userMobileNumber}
          utmParams={utmParams}
          setEditForm={setEditForm}
        />
      ) : isThankyouCardForExisting ? (
        renderThankYou()
      ) : null}
    </>
  );
};

export default NewBannerSection;
