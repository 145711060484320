import { gql } from "@apollo/client";

export const createOneMmwBMQuery = gql`
  mutation CreateOneMmw_bm_api_queries($data: Mmw_bm_api_queriesCreateInput) {
  createOneMmw_bm_api_queries(data: $data) {
    id
  }
}
`;

export const createOneMmwBMTracking = gql`
  mutation CreateOneMmw_bm_api_tracking($data: Mmw_bm_api_trackingCreateInput) {
  createOneMmw_bm_api_tracking(data: $data) {
    id
  }
}
`;

export const findFirstQuery = gql`
  query FindFirstMmw_bm_api_queries($where: Mmw_bm_api_queriesWhereInput) {
  findFirstMmw_bm_api_queries(where: $where) {
    id
  }
}
`;
