import "./style.css"
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createOneMmwBMQuery, createOneMmwBMTracking, findFirstQuery } from "../../apollo/createMmwBmQueries";
import axios from 'axios'

const BM_API_END_POINT ='https://wsbmapi.weddingbazaar.com'

const BMLeadCreation = () => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [QueryLoading, setQueryLoading] = useState(false);
    const [searchParams] = useSearchParams()
    const matriId = searchParams.get("matri_id");
    const appType = searchParams.get("APPTYPE");
    const appVersion = searchParams.get("APPVERSION");
    const bearerToken = searchParams.get("AT") || searchParams.get("at");
    const src = searchParams.get("ENCID") || searchParams.get("src") ;
    const tokenId = searchParams.get("TOKENID");
    const appTypeInt = (appType==null)?(0):(parseInt(appType))
    const source = searchParams.get("source") || searchParams.get("Source") ;
    const onOpen = () => { setOpenDrawer(true) }

    //console.log(matriId)
    const onClose = () => { 
        setOpenDrawer(false);
        if(([101,102,103].includes(appTypeInt)) || (appTypeInt>300)){ //ios
            const iosevent = '{"event_name":"WebViewClose"}';
            (window as any).webkit.messageHandlers.buttonClicked.postMessage(iosevent);
        }else if(appTypeInt==115){ //pwa
            window.history.back();
        }else{
            window?.onWebAppsClick?.onResponse('{"event_name": "PageClose"}');
        }
    }

    const [createMMWBMTrackingClick] = useMutation(createOneMmwBMTracking)
    const [createMMWBMTracking, { loading: TrackingLoading }] = useMutation(createOneMmwBMTracking)
    //const [createMMWBMQuery, { loading: QueryLoading }] = useMutation(createOneMmwBMQuery)
    const [findQuery] = useLazyQuery(findFirstQuery);


    //create tracking
    useEffect(() => {
        if (matriId && source) {
            createMMWBMTrackingClick({
                variables: {
                    data: {
                        event: "click",
                        matri_id: matriId,
                        source: source
                    }
                }
            });
        } else {
            console.log("Tracking event skipped");
        }
    }, [matriId, source]);

    const createMMWBMQueries = async () => {
        try {

            if (!matriId || !source) {
                console.error("Invalid matriId or source");
                return;
            }

            setQueryLoading(true);
            const { data } = await findQuery({
                variables: {
                    where: { matri_id: { equals: matriId } }
                },
                fetchPolicy: "network-only"
            });

            if (data?.findFirstMmw_bm_api_queries) {

                await createMMWBMTracking({
                    variables: {
                        data: { event: "Submit", matri_id: matriId, source }
                    }
                });
               // onOpen();
                
            }else{
                await Promise.all([
                    createMMWBMTracking({
                        variables: {
                            data: { event: "Submit", matri_id: matriId, source }
                        }
                    })
                ]);
            }

            await axios.get(BM_API_END_POINT+'/api/ws-business/create-mmw-bm-api-queries-get', {
                params: {
                    "matri_id": matriId,
                    "bearer": bearerToken,
                    "src": src,
                    "token_id": tokenId,
                    "app_type": appType,
                    "source": source,
                    "app_version": appVersion,
                    "event": "test"
                }
            });

            /* const postData = JSON.stringify({
                "matri_id": matriId,
                "bearer": bearerToken,
                "src": src,
                "token_id": tokenId,
                "app_type": appType,
                "source": source,
                "app_version": appVersion,
                "event": "test"
            });

             const config = {
                method: 'post',
                url: BM_API_END_POINT+'/api/ws-business/create-mmw-bm-api-queries',
                headers: {
                    'Content-type': 'application/json'
                },
                data : postData
            }; 

            await axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            }); */

          //alert('open')
            setQueryLoading(false);
            onOpen();
            return;
        } catch (error) {
            console.error("Error in createMMWBMQueries:", error);
        }
    };


    const onBackClick = () => {
        if(([101,102,103].includes(appTypeInt)) || (appTypeInt>300)){ //ios
            const iosevent = '{"event_name":"WebViewClose"}';
            (window as any).webkit.messageHandlers.buttonClicked.postMessage(iosevent);
        }else if(appTypeInt==115){ //pwa
            window.history.back();
        }else{
            window?.onWebAppsClick?.onResponse('{"event_name": "PageClose"}');
        }
    }

    return (
        <Box className="mmw-container">
            <ArrowBackIcon onClick={onBackClick} />
            <Box className="top-wrapper">
                <Box className="logo">
                    <img src="https://img.weddingbazaar.com/shaadisaga_production/static/makemyweddinglogo.png" alt="" />
                </Box>
                <Box className="paid-text"><img src="https://img.weddingbazaar.com/shaadisaga_production/static/crown.png" alt="" />
                    <Typography sx={{
                        fontSize: "10px",
                        fontWeight: "500",
                        fontFamily: "Poppins !important"
                    }}>Exclusive for Matrimony.com paid members</Typography></Box>
                <Typography
                    sx={{
                        fontSize: "24px",
                        fontWeight: "800",
                        fontFamily: "Lato !important",
                        lineHeight: "28px",
                        textAlign: "center"
                    }}
                >Save time, effort & money
                    on your dream wedding!</Typography>

                <Box className="expert-text"><img src="https://img.weddingbazaar.com/shaadisaga_production/static/free.png" alt="" />
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "Lato !important",
                        lineHeight: "14.5px"

                    }}>Seek our Expert's help in booking any one wedding related service for <span>FREE</span></Typography></Box>
            </Box>

            <Box className="bottom-wrapper">
                <Box className="hwt-text"><img src="https://img.weddingbazaar.com/shaadisaga_production/static/stars.png" alt="" />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            fontFamily: "Lato !important",

                        }}
                    >How it works?</Typography>
                    <img src="https://img.weddingbazaar.com/shaadisaga_production/static/stars.png" alt="" />
                </Box>
                <Box className="how-it-works-card">
                    <Box className="hwt-content-container">
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10%" }}>
                            <img src="https://img.weddingbazaar.com/shaadisaga_production/static/requirements.png" alt="" />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        lineHeight: "19.6px",
                                        fontFamily: "Lato !important",
                                    }}
                                >
                                    Share your requirements
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Lato !important",
                                        lineHeight: "19.6px",
                                    }}
                                >
                                    Share your event date, budget, location, venue & vendor
                                    preferences with our Relationship Managers
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: "1px",
                                background: "linear-gradient(to right, #77777766 50%, transparent 50%)",
                                backgroundSize: "4px 1px", // Adjust the spacing of the dots
                            }}
                        ></Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10%" }}>
                            <img src="https://img.weddingbazaar.com/shaadisaga_production/static/vendors.png" alt="" />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        lineHeight: "19.6px",
                                        fontFamily: "Lato !important",
                                    }}
                                >
                                    Get Vendor recommendations
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Lato !important",
                                        lineHeight: "19.6px",
                                    }}
                                >
                                    We'll recommend trusted and reliable vendors, handle price negotiations to get you the best deals
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: "1px",
                                background: "linear-gradient(to right, #77777766 50%, transparent 50%)",
                                backgroundSize: "4px 1px", // Adjust the spacing of the dots
                            }}
                        ></Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10%" }}>
                            <img src="https://img.weddingbazaar.com/shaadisaga_production/static/customer_support.png" alt="" />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        lineHeight: "19.6px",
                                        fontFamily: "Lato !important",
                                    }}
                                >
                                    Receive guidance from start to finish
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Lato !important",
                                        lineHeight: "19.6px",
                                    }}
                                >
                                    Our Relationship Manager will provide personalised support to you at every step, ensuring a seamless wedding planning experience.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: "1px",
                                background: "linear-gradient(to right, #77777766 50%, transparent 50%)",
                                backgroundSize: "4px 1px", // Adjust the spacing of the dots
                            }}
                        ></Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10%" }}>
                            <img src="https://img.weddingbazaar.com/shaadisaga_production/static/discount.png" alt="" />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        lineHeight: "19.6px",
                                        fontFamily: "Lato !important",
                                    }}
                                >
                                    Unlock Exclusive Shopping Discounts</Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Lato !important",
                                        lineHeight: "19.6px",
                                    }}
                                >
                                    Unlock exclusive offers from a wide range of top brands.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                position: "sticky",
                bottom: "0",
                display: "flex",
                justifyContent: "center"

            }}>

                <Button
                    onClick={() => {
                        createMMWBMQueries()
                    }}
                    disabled={TrackingLoading || QueryLoading}
                    variant="contained"
                    sx={{
                        "&.Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.96)",
                            backgroundColor: "#e08606d1"
                        },
                        textTransform: "none",
                        width: {
                            xs: "100%",
                            md: "263px"
                        },
                        height: "52px",
                        padding: "12px 24px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                        flexShrink: 0,
                        borderRadius: "50px",
                        border: "1px solid #B37839",
                        background: "#E06506",
                        color: "#FFF",
                        fontFamily: "Lato !important",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        mt: 4,
                        mb: 2
                    }}
                >
                    {TrackingLoading || QueryLoading ? "Processing..." : "Get a call back"}
                </Button>
                <Dialog
                    open={openDrawer}
                    PaperProps={{
                        sx: {
                            borderRadius: "20px",
                            margin: "unset",
                            width: "auto",
                            height: "auto"
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: "auto",
                            width: "100%",
                            maxWidth: "352px",
                        }}
                    >
                        {/* Close button */}
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                        }}>

                            <IconButton
                                onClick={onClose}
                                sx={{
                                    pt: 4,
                                    pr: 3,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <DialogContent
                            sx={{ paddingTop: "0 !important" }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                <img className="success-img" src="https://img.weddingbazaar.com/shaadisaga_production/static/success.png" alt="" />
                                <Typography
                                    sx={{ fontSize: "20px", fontWeight: "700", fontFamily: "Lato !important" }}
                                >Thank you for your interest!</Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        fontFamily: "Lato !important",
                                        lineHeight: "22.5px"
                                    }}
                                >We will contact you shortly to help plan your wedding</Typography>
                            </Box>
                        </DialogContent>
                    </Box>
                </Dialog>
            </Box>
        </Box>
    )
}

export default BMLeadCreation
